// react
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// store
import { routes } from "../../store/routes";
// components
import RegisterTypeCard from "./RegisterTypeCard";
import { Grid, Header, Divider, Label } from "semantic-ui-react";
// others
import { GAPageView, initGA } from "../../index";

// component
function RegisterTypeSelect() {
    // Google analytics
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    // template
    return (
        <>
            <Grid centered>
                <Grid.Row>
                    <Header as="h2">Vyber si jednu z možností</Header>
                </Grid.Row>
                <Grid.Row style={{ textAlign: "center" }} divided="horizontal">
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <Link to={routes.REGISTER_CLIENT}>
                            <RegisterTypeCard
                                header="Chcem sa registrovať ako užívateľ"
                                icon="futbol outline"
                                text="Ako užívateľ si vyhľadáš aktivity, ktoré si následne užiješ."
                            />
                        </Link>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />
            <Grid centered>
                <Grid.Row style={{ textAlign: "center" }}>
                    <Grid.Column style={{ textAlign: "center" }}>
                        <Header as="h3" style={{ marginBottom: "0.5rem" }}>
                            Registrácia zadávateľa
                        </Header>
                        <p>
                            Vyber si jeden z dostupných programov a vyplň
                            nezaväzný formulár
                        </p>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                            }}
                        >
                            <Label
                                as={Link}
                                to={routes.AP_SOLIDARITY}
                                style={{
                                    background: "rgb(53, 119, 223)",
                                    color: "#000000B3",
                                    padding: "1rem 1.5rem",
                                    fontFamily: "Metropolis Bold",
                                }}
                            >
                                #APsolidarity
                            </Label>
                            <Label
                                as={Link}
                                to={routes.AP_BUSINESS}
                                style={{
                                    background: "rgb(255, 226, 0)",
                                    color: "#000000B3",
                                    padding: "1rem 1.5rem",
                                    fontFamily: "Metropolis Bold",
                                }}
                            >
                                #APimage
                            </Label>
                            <Label
                                as={Link}
                                to={routes.AP_EVENT}
                                style={{
                                    background: "rgb(80, 217, 13)",
                                    color: "#000000B3",
                                    padding: "1rem 1.5rem",
                                    fontFamily: "Metropolis Bold",
                                }}
                            >
                                #APevent
                            </Label>
                            <Label
                                as={Link}
                                to={routes.AP_ACTION}
                                style={{
                                    background: "red",
                                    color: "#000000B3",
                                    padding: "1rem 1.5rem",
                                    fontFamily: "Metropolis Bold",
                                }}
                            >
                                #APwalkIn
                            </Label>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
}

export default RegisterTypeSelect;
