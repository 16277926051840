// react
import React from "react";
import { Grid, Header, Icon, Container } from "semantic-ui-react";

// component
function GeneralConditionsText() {
    // template
    return (
        <Grid className="section">
            <Grid.Row columns={1} centered>
                <Header as="h2" style={{ color: "var(--primary)" }}>
                    VŠEOBECNÉ PODMIENKY POUŽÍVANIA
                </Header>
                <Header as="h4" style={{ marginTop: "0.5rem" }}>
                    ActivityPoint
                </Header>
                <Icon name="angle down" size="big" color="pink" />
            </Grid.Row>

            <Grid.Row columns={1} centered width={10}>
                <Container text style={{ textAlign: "justify" }}>
                    <Header as="h3" style={{ textAlign: "center" }}>
                        I. Úvod
                    </Header>
                    <ol>
                        <li>
                            <p>
                                Obchodná spoločnosť{" "}
                                <strong>AB & Partners, s.r.o.</strong>, so
                                sídlom: Námestie slobody 4, 066 01 Humenné, IČO:
                                50 711 946, zapísaná v Obchodnom registri
                                vedeným Okresným súdom Prešov, v oddiele: Sro,
                                vložke číslo: 34078/P (ďalej len
                                „prevádzkovateľ“) je spoločnosť založená a
                                existujúca podľa právneho poriadku Slovenskej
                                republiky, ktorá je prevádzkovateľom a správcom
                                webovej stránky www.activitypoint.xyz a mobilnej
                                aplikácie ActivityPoint (ďalej spolu ako
                                “platforma ActivityPoint”). Prevádzkovateľ je
                                zároveň jediným vlastníkom, autorom, ako aj
                                správcom a prevádzkovateľom platformy
                                ActivityPoint, jediným držiteľom aj vlastníkom
                                všetkých duševných, autorských, priemyselných a
                                iných majetkových práv spojených s touto
                                platformou a teda je jediným subjektom
                                oprávneným na udeľovanie akýchkoľvek (všetkých)
                                súhlasov a povolení pre užívanie a nakladanie s
                                platformou.
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                Prevádzkovateľ prostredníctvom platformy
                                Activitypoint umožňuje za podmienok stanovených
                                v týchto Všeobecných podmienkach používania (v
                                ďalšom texte ako „VPP“) vopred neurčenému počtu
                                subjektov, ponúkajúcich aktivity rôzneho druhu
                                (najmä aktivity športové, pohybové, kultúrne,
                                voľnočasové, rodinné, ekologické, a podobne) -
                                ako ich zadávateľom, takéto aktivity :
                            </p>
                            <ol>
                                <li>
                                    prezentovať a zviditeľniť pre širokú
                                    verejnosť ponúkané aktivity prostredníctvom
                                    platformy ActivityPoint,
                                </li>
                                <li>
                                    prostredníctvom internetových služieb
                                    prevádzkovateľa ich ponúkať (odplatne alebo
                                    bezodplatne) pre subjekty vyhľadávajúce
                                    takéto aktivity ako záujemcov o aktivity,
                                </li>

                                <br />
                            </ol>
                            <p>
                                čím prevádzkovateľ umožňuje súčasne zadávateľom
                                prezentovať a ponúkať nimi ponúkajúce a
                                zadefinované aktivity a adresátom týchto ponúk
                                (záujemcom o aktivity) umožňuje vyhľadať
                                informácie o type, miestach a časoch takto
                                ponúkaných aktivít zadávateľmi. Takto sa pomocou
                                platformy ActivityPoint vytvára samotná možnosť
                                prepojenia, ako aj reálne priame prepojenie,
                                ponuky aktivít (zadávateľmi) a dopytu po
                                takýchto aktivitách (záujemcami).
                            </p>

                            <br />
                        </li>
                        <li>
                            <p>
                                Každý subjekt (fyzická osoba, alebo právnická
                                osoba, bez ohľadu na miesto bydliska alebo
                                registrované sídlo subjektu) využívajúci
                                možnosti prezentovania a ponuky svojich aktivít
                                – označovaný ako Zadávateľ - prostredníctvom
                                www.activitypoint.xyz sa zaväzuje dodržiavať
                                tieto VPP momentom vyplnenia a potvrdenia
                                nezáväzného registračného formuláru a/alebo
                                nezáväznej žiadosti o registráciu a/alebo
                                nezáväznej žiadosti o objednávku (ktorýkoľvek z
                                uvedených úkonov ďalej aj len ako „žiadosť“)
                                prostredníctvom webovej stránky
                                www.activitypoint.xyz a týmto momentom je
                                viazaný týmito VPP. Vyplnením a odoslaním
                                žiadosti vyjadruje konkrétny subjekt plný súhlas
                                s celým obsahom VPP, ako aj to že sa ešte pred
                                vyplnením a odoslaním žiadosti s týmito VPP
                                oboznámil takým spôsobom, že im porozumel v
                                celom rozsahu a rešpektuje ich záväznosť pre
                                vzťahy medzi ním a Prevádzkovateľom.
                            </p>

                            <br />
                        </li>
                        <li>
                            <p>
                                Každý subjekt (fyzická osoba, alebo právnická
                                osoba, bez ohľadu na miesto bydliska alebo
                                registrované sídlo subjektu), ktorý vyhľadáva
                                aktivity ponúkané v platforme ActivityPoint –
                                označovaný ako Užívateľ aktivity - sa zaväzuje
                                dodržiavať tieto VPP a je viazaný týmito VPP
                                momentom stiahnutia (alebo inej formy
                                aktivovania a používania) platformy
                                ActivityPoint do ním využívaného elektronického
                                zariadenia (napr. mobilný telefón, tablet,
                                počítač). Vykonaním úkonov smerujúcich k
                                stiahnutiu (alebo inej formy aktivovania a
                                používania) platformy ActivityPoint vyjadruje
                                konkrétny subjekt plný súhlas s celým obsahom
                                VPP, ako aj to že sa ešte pred svojou
                                registráciou s týmito VPP oboznámil takým
                                spôsobom, že im porozumel v celom rozsahu a
                                rešpektuje ich záväznosť pre vzťahy medzi ním a
                                Prevádzkovateľom.
                            </p>

                            <br />
                        </li>
                        <li>
                            <p>
                                Tieto VPP bližšie špecifikujú práva a povinnosti
                                subjektov využívajúcich platformu ActivityPoint.
                                Tieto VPP sú k dispozícii na prehliadanie aj
                                stiahnutie prostredníctvom platformy
                                ActivityPoint, čím sa zabezpečuje možnosť ich
                                slobodného preskúmania a vyhodnotenia každému
                                užívateľovi a zadávateľovi, ktorý využije
                                platformu ActivityPoint a to aj subjektom, ktoré
                                nie sú registrované ani inak evidované v
                                platforme ActivityPoint.
                            </p>
                        </li>
                    </ol>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        II. Vymedzenie základných pojmov
                    </Header>

                    <ol>
                        <li>
                            <p>
                                <em>„Aktivitou“</em> sa pre účely týchto VPP
                                rozumie akákoľvek aktívna fyzická činnosť,
                                pasívna fyzická činnosť, prípadne duševná
                                činnosť a/alebo vstup do prevádzok alebo iných
                                priestorov poskytujúcich niektorú z uvedených
                                činností, ktorú ponúka zadávateľ aktivity pre
                                užívateľov aktivity, najmä aktivity športové,
                                pohybové, kultúrne, voľnočasové, rodinné,
                                ekologické, a podobne. Prostredníctvom platformy
                                ActivityPoint sa neponúkajú (sú zakázané)
                                aktivity ani ponuky, ktoré porušujú akékoľvek
                                právne predpisy platné na území Slovenskej
                                republiky, smerujú alebo nabádajú k ohrozovaniu
                                života, zdravia, majetku alebo iných práv
                                fyzických alebo právnických osôb, smerujú k
                                erotickým alebo iným nemravným činnostiam alebo
                                cieľom, propagujú násilie alebo nabádajú k
                                porušovaniu ľudských práv, alebo majú politický
                                obsah (cieľ).V rámci VPP a platformy
                                ActivityPoint sa rozlišujú Aktivity do skupín :
                            </p>
                            <ol type="1">
                                <li>
                                    <em>„bezplatná aktivita“</em> ktorou sa pre
                                    účely týchto VPP rozumie aktivita, s ktorej
                                    objednávkou a následným využitím nie je
                                    spojená povinnosť platby zo strany Užívateľa
                                    aktivity;
                                </li>
                                <li>
                                    <em>„odplatná aktivita“</em> ktorou sa pre
                                    účely týchto VPP rozumie aktivita, s ktorej
                                    objednávkou a následným využitím je spojená
                                    povinnosť platby zo strany Užívateľa
                                    aktivity a to v sume a v čase definovanom
                                    Prevádzkovateľom v platforme ActivityPoint.
                                </li>
                            </ol>
                            <br />
                        </li>
                        <li>
                            <p>
                                <em>„Zadávateľom aktivity“</em> sa pre účely
                                týchto VPP rozumie subjekt (fyzická osoba –
                                podnikateľ /podnikajúci na základe
                                živnostenského alebo iného ako živnostenského
                                oprávnenia/, alebo právnická osoba, bez ohľadu
                                na miesto bydliska alebo registrované sídlo
                                subjektu) ponúkajúci Aktivitu, ktorý sa
                                príslušným Prevádzkovateľom zadefinovaným
                                spôsobom zaregistroval prostredníctvom platformy
                                ActivityPoint a ponúkanú aktivitu špecifikoval
                                podľa rozsahu vyžadovaným pre úspešné
                                evidovanie, najmä jej druhom a miestom jej
                                realizácie. Zadávateľ aktivity zadáva
                                Prevádzkovateľovi svoje údaje v rozsahu
                                špecifikovanom v registračnom formulári, pričom
                                pre úspešnú aktiváciu registrácie je potrebné
                                jeho vyjadrenie súhlasu s týmito VPP.
                                Prevádzkovateľ posudzuje individuálne každý
                                vyplnený registračný formulár od Zadávateľa
                                aktivity, ktorý vyplnil registračný formulár,
                                pričom účet Zadávateľa aktivity sa aktivuje až
                                po schválení zo strany Prevádzkovateľa.
                                Prevádzkovateľ nie je povinný schváliť každý
                                vyplnený registračný formulár, pričom na
                                schválenie registračného formulára neexistuje
                                právny nárok. V prípade neschválenia
                                registračného formulára Prevádzkovateľom, bude
                                neschválenému Zadávateľovi aktivity zaslané
                                oznámenie o neúspešnej registrácii.
                            </p>
                            <p>
                                V rámci VPP a platformy ActivityPoint sa
                                rozlišujú Zadávatelia aktivity do skupín :
                            </p>
                            <ol type="1">
                                <li>
                                    <em>„Zadávateľ samospráva“</em> v programe
                                    #APsolidarity, ktorým sa pre účely týchto
                                    VPP rozumie subjekt - najmä, nie však
                                    výlučne jednotka územnej samosprávy, ktorým
                                    je obec, mesto alebo samosprávny kraj a
                                    zároveň akýkoľvek iný subjekt ponúkajúci
                                    bezplatné aktivity Užívateľom aktivity.
                                    Výlučnou podmienkou pre registráciu tohto
                                    typu Zadávateľa aktivity je skutočnosť, že
                                    tento typ Zadávateľa aktivity do platformy
                                    AcitivitPoint registruje výlučne
                                    Prevádzkovateľ po vyplnení nezáväzného
                                    registračného formulára. Podkladom pre
                                    ponúkanie Aktivity alebo Aktivít Zadávateľom
                                    samospráva prostredníctvom platformy
                                    ActivityPoint za podmienok uvedených v
                                    týchto VPP je písomná zmluva uzavretá medzi
                                    Zadávateľom samospráva a Prevádzkovateľom.
                                </li>
                                <li>
                                    <em>„Zadávateľ business“</em> v programe
                                    #APbusiness ktorým sa pre účely týchto VPP
                                    rozumie subjekt s podnikateľským oprávnením,
                                    zapísaný do obchodného registra,
                                    živnostenského registra, alebo osoba
                                    podnikajúca na základe iného ako
                                    živnostenského oprávnenia ponúkajúcich
                                    odplatné aktivity prostredníctvom platformy
                                    ActivityPoint za finančnú odplatu;
                                </li>
                            </ol>
                            <br />
                        </li>
                        <li>
                            <p>
                                <em>„Užívateľ aktivity“</em> sa pre účely týchto
                                VPP rozumie subjekt (fyzická osoba, bez ohľadu
                                na miesto bydliska subjektu) aktívne hľadajúci
                                aktivitu, ktorý príslušným Prevádzkovateľom
                                zadefinovaným spôsobom využíva platformu
                                ActivityPoint a prostredníctvom nej a ňou
                                zadaných kritérií vyhľadáva konkrétny druh
                                aktivity, na konkrétnom mieste a jeho okolí.
                                Užívateľ aktivity nie je povinný sa pre pasívne
                                využívanie platformy ActivityPoint spočívajúci
                                len v prezeraní ponuky Aktivít v jeho okolí
                                registrovať, samotným využívaním platformy
                                ActivityPoint však vyjadruje súhlas s týmito
                                VPP. Registrácia Užívateľa aktivity je potrebná
                                výlučne v prípade, ak má záujem o aktívne
                                využívanie Aktivít ponúkaných prostredníctvom
                                platformy ActivityPoint a teda prispievaním
                                Zadávateľovi aktivít, hodnotením Zadávateľa
                                aktivít alebo nákupom Aktivít ponúkaných
                                Zadávateľom aktivít.
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                <em>„Webová stránka www.activitypoint.xyz“</em>{" "}
                                je web vlastnený a prevádzkovaný
                                Prevádzkovateľom, ktorý je súčasťou platformy
                                Activitypoint, prostredníctvom ktorého sa plnia
                                príslušné úlohy a ciele definované v týchto VPP,
                                za používania ktorého po splnení podmienok
                                registrácie je Zadávateľ aktivity oprávnený
                                ponúkať príslušné aktivity pre Užívateľov
                                aktivity, ktoré si Užívateľ aktivity môže
                                vyhľadať podľa ním zadaných kritérií v platforme
                                ActivityPoint.
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                <em>„Aplikácia ActivityPoint“</em> je webová
                                aplikácia, ktorá je súčasťou platformy
                                ActivityPoint, ktorú môžu využívať Užívatelia
                                aktivity a Zadávatelia aktivity prostredníctvom
                                príslušného zariadenia. Aplikácia je vlastnená a
                                prevádzkovaná Prevádzkovateľom a je
                                identifikovateľná prostredníctvom loga platformy
                                ActivityPoint. Aplikácia funguje na bežne
                                dostupných platformách, a pre jej úspešné
                                používanie sa vyžadujú príslušné technické
                                zariadenia (napr. mobilný telefón, tablet,
                                počítač).
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                <em>„Bankou“</em> sa rozumie Tatra banka, a.s.,
                                so sídlom: Hodžovo námestie 3, Bratislava, IČO:
                                00686930, ktorá podľa osobitnej zmluvy a
                                osobitne dohodnutých podmienok s
                                Prevádzkovateľom je správcom platobných systémov
                                používaných platformou ActivityPoint.
                            </p>
                        </li>
                    </ol>
                    <Header as="h3" style={{ textAlign: "center" }}>
                        III. Podmienky registrácie a ponuky aktivít Zadávateľmi
                        aktivít
                    </Header>
                    <ol>
                        <li>
                            <p>
                                Zadávateľ aktivity, ktorý má záujem ponúknuť ním
                                vykonávanú (alebo zabezpečovanú) aktivitu
                                Užívateľom aktivity, ktorí si ju majú možnosť
                                vyhľadať prostredníctvom platformy
                                ActivityPoint, je povinný vyplniť a odovzdať
                                žiadosť prostredníctvom platformy ActivityPoint.
                                Po vecnej a formálnej kontrole príslušnej
                                žiadosti Prevádzkovateľ buď potvrdí a príjme
                                takúto žiadosť Zadávateľa aktivity alebo ju
                                odmietne. V prípade ak zo strany Prevádzkovateľa
                                nedôjde k potvrdeniu žiadosti v lehote 7 dní,
                                platí domnienka, že Prevádzkovateľ žiadosť
                                odmietol. Bez vyhotovenia a odoslania príslušnej
                                žiadosti a jej potvrdenia zo strany
                                Prevádzkovateľa sa žiaden subjekt nemôže domáhať
                                oprávnenia ponúkať svoje aktivity
                                prostredníctvom platformy ActivityPoint.
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                Žiadosť sa podáva prostredníctvom platformy
                                ActivityPoint a odkazu „Ponúkam aktivity“,
                                pričom po kliknutí na príslušný program v ponuke
                                Prevádzkovateľa vyplní Zadávateľ aktivity
                                požadovaný formulár. Po riadnom zadaní
                                požadovaných údajov a odoslaní žiadosti
                                Prevádzkovateľ skontroluje žiadosť po vecnej a
                                formálnej stránke a v prípade neúplnosti alebo
                                nejasnosti žiadosti vyzve Zadávateľa aktivity na
                                jej doplnenie alebo opravu. Prevádzkovateľ
                                žiadosť buď potvrdí alebo odmietne a to v lehote
                                do 7 kalendárnych dní odo dňa prijatia žiadosti.
                                V prípade ak vo vyššie uvedenej lehote nedôjde k
                                potvrdeniu žiadosti má sa za to, že
                                Prevádzkovateľ žiadosť odmietol. Prevádzkovateľ
                                si vyhradzuje právo odmietnuť žiadosť Zadávateľa
                                aktivity bez udania akéhokoľvek dôvodu. Na
                                uzatvorenie spolupráce medzi Prevádzkovateľom a
                                Zadávateľom aktivity neexistuje právny nárok.
                                Pokiaľ Prevádzkovateľ vykoná potvrdenie žiadosti
                                Zadávateľa aktivity, vytvorí mu profil s ponukou
                                Aktivít o ktoré žiada v prípade, ak ho Zadávateľ
                                aktivity ešte nemá vytvorený.
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                Zadávateľ aktivity berie na vedomie a
                                potvrdzuje, že preberá zodpovednosť za reálnosť
                                a pravdivosť ním zadaných aktivít a údajov a v
                                prípade vzniku škody tretej osobe spôsobenej
                                nepravdivosťou a nereálnosťou ním zadaných
                                údajov za takúto škodu v plnej miere zodpovedá.
                                Prevádzkovateľ nevykonáva automatické overenie
                                pravdivosti alebo reálnosti údajov zadaných
                                samotným Zadávateľom aktivity, ani nevykonáva
                                pokusné využívanie takto zverejnených aktivít.
                            </p>
                        </li>
                    </ol>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        IV. Vyhľadávanie aktivít Záujemcami o aktivity
                    </Header>
                    <ol>
                        <li>
                            <p>
                                Užívatelia aktivity sú oprávnení prostredníctvom
                                aplikácie ActivityPoint vyhľadávať aktivity
                                ponúkané Zadávateľmi aktivity na základe
                                kritérií zadaných do platformy ActivityPoint.
                                Užívateľ aktivity je plne oprávnený slobodne sa
                                rozhodnúť, či sa na vyhľadanej aktivite
                                zúčastní, a takisto samostatne rozhoduje o tom,
                                aké aktivity si vyhľadá a zrealizuje ich, pričom
                                samostatne v plnom rozsahu nesie zodpovednosť za
                                vhodnosť a primeranosť ním vyhľadanej a následne
                                zrealizovanej aktivite na svoj vek, zdravotný
                                stav, fyzickú kondíciu. Nevyhnutnou podmienkou
                                pre zakúpenie aktivity Užívateľom aktivity je
                                vykonanie registrácie a súhlas s VPP.
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                Zadanými kritériami na vyhľadanie príslušnej
                                aktivity sú predovšetkým poloha, vzdialenosť od
                                aktuálnej polohy Užívateľa aktivity, dátum a čas
                                vyhľadávanej aktivity prípadne kategórie
                                aktivity. Po zadaní príslušných kritérií a ich
                                potvrdení sa v aplikácii ActivityPoint zobrazia
                                Užívateľovi aktivity ponúkané zadávateľom
                                aktivity. Podmienkou plného funkčného využívania
                                platformy ActivityPoint je udelenie súhlasu s
                                prístupom k aktuálnej polohe v príslušnom
                                technickom zariadení, ktorý Užívateľ aktivity
                                využíva.
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                Užívateľ aktivity berie na vedomie, že za
                                aktuálnosť a reálnosť ponúkaných aktivít nenesie
                                Prevádzkovateľ žiadnu zodpovednosť, nakoľko
                                zadávanie jednotlivých aktivít a ich
                                špecifikácia je vo výlučnej pôsobnosti
                                Zadávateľa aktivít vytvorená na základe
                                žiadosti. Prevádzkovateľ nevykonáva automatické
                                overenie pravdivosti alebo reálnosti údajov
                                zadaných samotným Zadávateľom aktivity, ani
                                nevykonáva pokusné využívanie takto zverejnených
                                aktivít.
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                V prípadoch ak Užívateľ aktivity zistí, že
                                Aktivita zadaná Zadávateľom aktivity nebola v
                                uvedených podmienkach (mieste, čase,
                                vlastnostiach, kvalite) dostupná, je oprávnený o
                                tejto skutočnosti informovať Prevádzkovateľa.
                                Takéto oznámenie nie je možné vykonať
                                prostredníctvom samotnej platformy
                                ActivityPoint. Prevádzkovateľ o takýchto
                                informáciách od Užívateľov aktivity bude
                                informovať dotknutých Zadávateľov aktivít bez
                                zbytočného odkladu.
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                Záujemca o aktivitu podľa podmienok a informácií
                                ohľadne konkrétnej zverejnenej a ním zvolenej
                                aktivity berie na vedomie rozdielnosť aktivít, z
                                ktorých niektoré sú bezplatné aktivity a
                                niektoré odplatné aktivity.
                            </p>
                        </li>
                    </ol>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        V. Program AP Solidarity
                    </Header>
                    <ol>
                        <li>
                            <p>
                                V rámci platformy ActivityPoint a ponuky aktivít
                                sú najmä mestá, obce a samosprávne kraje,
                                prípadne iný Zadávateľ bezplatnej aktivity,
                                oprávnení požiadať o Program AP Solidarity,
                                ktorý predstavuje možnosť zobrazenia a
                                prezentácie bezplatných aktivít nachádzajúcich
                                sa na ich území, prevádzke alebo inom priestore
                                a aj možnosť prijatia dobrovoľného finančného
                                príspevku určeného na rozvoj konkrétneho miesta
                                zobrazenia prostredníctvom tlačidla “Prispieť” a
                                s voľbou minimálnej sumy príspevku presne
                                vymedzenej v zmluve medzi Zadávateľom aktivity a
                                Prevádzkovateľom.
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                Prevádzkovateľ po schválení žiadosti a úhrade
                                platby zo strany Zadávateľa aktivity, ktorým v
                                tomto programe môže byť najmä obec, mesto alebo
                                samosprávny kraj, prostredníctvom svojej
                                platformy zobrazí na mape modrý bod, na ktorom
                                sa aktivita nachádza a prostredníctvom ktorého
                                chce na konkrétnu aktivitu alebo aktivity na
                                svojom území aj s požadovanými informáciami
                                upozorniť obyvateľov alebo návštevníkom mesta,
                                obce alebo samosprávneho kraja. Cena za jeden
                                zobrazený bod modrej farby na mape počas jedného
                                mesiaca predstavuje sumu podľa aktuálne platného
                                cenníka Prevádzkovateľa, pričom za mesiac sa pre
                                účely týchto VPP považuje 30 kalendárnych dní.
                                Začiatok mesiaca sa začína deň nasledujúci po
                                dni pripísania platby na účet Prevádzkovateľa.
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                Žiadosťou sa pre program AP Solidarity považuje
                                „Nezáväzný registračný formulár“ nachádzajúci sa
                                na webovej stránke www.activitypoint.xyz v
                                sekcii #APsolidarity, ktorý Zadávateľ aktivity
                                vyplní minimálne v rozsahu povinných údajov
                                označených červenou hviezdičkou.
                            </p>
                        </li>
                    </ol>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        VI. Program AP Business
                    </Header>
                    <ol>
                        <li>
                            <p>
                                V rámci programu AP Business ponúka
                                Prevádzkovateľ Zadávateľom aktivity možnosť
                                podnikateľskej prezentácie svojich aktivít a to
                                prostredníctvom foriem:
                                <br />
                                <br />
                                A. #APimage
                                <br />
                                B. #APevent
                                <br />
                            </p>
                        </li>
                    </ol>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        #APimage
                    </Header>

                    <ol>
                        <li>
                            <p>
                                V rámci platformy ActivityPoint a ponuky aktivít
                                sú subjekty ponúkajúce odplatné aktivity v rámci
                                svojej podnikateľskej činnosti oprávnení
                                požiadať o program #APimage, prostredníctvom
                                ktorého môžu po splnení podmienok uvedených v
                                týchto VPP zviditeľniť svoje podnikateľské
                                aktivity spočívajúce najmä v pohybových,
                                športových, edukačných, kultúrnych alebo
                                historických aktivít.
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                Prevádzkovateľ po schválení žiadosti a úhrade
                                platby zo strany Zadávateľa aktivity vo výške
                                podľa aktuálne platného cenníka
                                Prevádzkovateľa,, prostredníctvom svojej
                                platformy zobrazí na mape bod žltej farby, na
                                ktorom sa ponúkaná aktivita nachádza a
                                prostredníctvom ktorého chce na konkrétnu
                                aktivitu alebo aktivity na svojom území
                                upozorniť záujemcov aj s požadovanými
                                informáciami o aktivite a ktorých v prípade
                                potreby navigácie k zobrazenému bodu naviguje.
                                Cena za jeden zobrazený bod na mape počas
                                jedného mesiaca predstavuje sumu vo výške podľa
                                aktuálne platného cenníka Prevádzkovateľa,
                                pričom za mesiac sa pre účely týchto VPP
                                považuje 30 kalendárnych dní. Začiatok mesiaca
                                sa začína deň nasledujúci po dni pripísania
                                platby na účet Prevádzkovateľa.
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                Žiadosťou sa pre program APimage považuje
                                „Nezáväzná žiadosť o registráciu“ nachádzajúci
                                sa na platforme ActivityPoint v sekcii
                                #APbusiness/#APimage, ktorú Zadávateľ aktivity
                                vyplní minimálne v rozsahu povinných údajov
                                označených červenou hviezdičkou.
                            </p>
                        </li>
                    </ol>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        #APevent
                    </Header>
                    <ol>
                        <li>
                            <p>
                                V rámci platformy ActivityPoint a ponuky aktivít
                                sú Zadávatelia aktivity v rámci svojej
                                podnikateľskej činnosti oprávnení požiadať o
                                program #APevent, prostredníctvom ktorého môžu
                                po splnení podmienok uvedených v týchto VPP
                                zviditeľniť a upozorniť na aktivitu ktorou je
                                najmä podujatie/festival prípadne iný event,
                                ktorý Zadávateľ aktivity organizuje v konkrétny
                                kalendárny deň.
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                Prevádzkovateľ po schválení žiadosti a úhrade
                                platby zo strany Zadávateľa aktivity,
                                prostredníctvom platformy ActivityPoint zobrazí
                                na mape viditeľne blikajúci bod zelenej farby,
                                na ktorom sa ponúkaná aktivita nachádza a
                                prostredníctvom ktorého chce na konkrétnu
                                aktivitu alebo aktivity upozorniť Užívateľov
                                aktivít aj s požadovanými informáciami o
                                ponúkanej Aktivite a ktorých v prípade potreby
                                navigácie k zobrazenému bodu naviguje. Cena za
                                jeden zobrazený blikajúci bod zelenej farby na
                                mape počas jedného kalendárneho dňa predstavuje
                                sumu vo výške podľa aktuálne platného cenníka
                                Prevádzkovateľa.
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                Žiadosťou sa pre program APevent považuje
                                „Nezáväzná žiadosť o objednávku“ nachádzajúci sa
                                na platforme ActivityPoint v sekcii
                                #APbusiness/#APevent, ktorú Zadávateľ aktivity
                                vyplní minimálne v rozsahu povinných údajov
                                označených červenou hviezdičkou.
                            </p>
                        </li>
                    </ol>

                    <Header as="h3" style={{ textAlign: "center" }}>
                        VIII. Ochrana osobných údajov
                    </Header>
                    <ol>
                        <li>
                            <p>
                                Prevádzkovateľ ako prevádzkovateľ platformy
                                ActivityPoint spracúva osobné údaje fyzických
                                osôb – používateľov platformy ActivityPoint
                                (ďalej len „dotknuté osoby“) ako prevádzkovateľ
                                v zmysle článku 4 ods. 7 Nariadenia Európskeho
                                parlamentu a Rady č. 2016/679 o ochrane
                                fyzických osôb pri spracúvaní osobných údajov a
                                o voľnom pohybe takýchto údajov (v ďalšom texte
                                ako „Nariadenie GDPR“) a v zmysle zákona č.
                                18/2018 Z.z. o ochrane osobných údajov a o zmene
                                a doplnení niektorých zákonov.
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                Aby mohol Zadávateľ aktivity ponúkať Aktivity
                                prostredníctvom platformy ActivityPoint je
                                povinný sa zaregistrovať prostredníctvom
                                platformy ActivityPoint a zadať potrebné osobné
                                údaje, najmä IČO (používané pri podnikaní alebo
                                inej činnosti Zadávateľa aktivity), mailovú
                                adresu, prípadne meno a priezvisko dotknutej
                                osoby a telefónne číslo.
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                Za týmto účelom spracúva Prevádzkovateľ osobné
                                údaje dotknutých osôb v nasledovnom rozsahu:
                                e-mailová adresa, meno a priezvisko, telefónne
                                číslo, online identifikátory (cookies, FB pixel
                                kódy, Google Adwords kódy).
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                Prevádzkovateľ spracúva osobné údaje dotknutých
                                osôb na základe svojho oprávneného záujmu v
                                zmysle článku 6 ods. 1 písm. f) Nariadenia GDPR.
                                Oprávneným záujmom je ponuka aktivít Zadávateľmi
                                aktivít prostredníctvom platformy ActivityPoint
                                a aj vykonávanie marketingu.
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                Spracúvané osobné údaje uchováva Prevádzkovateľ
                                po dobu oprávneného záujmu, t. j. kým záujmy
                                alebo základné práva a slobody dotknutých osôb
                                neprevážia nad takýmito záujmami. Zákonná
                                povinnosť uchovávania údajov v lehote určenej
                                osobitnými predpismi tým nie je dotknutá.{" "}
                            </p>
                            <br />
                        </li>
                        <li>
                            <p>
                                Poučenie o právach dotknutej osoby: Dotknutá
                                osoba má právo získať od prevádzkovateľa
                                potvrdenie o tom, či sa spracúvajú osobné údaje,
                                ktoré sa jej týkajú. Ak prevádzkovateľ takéto
                                osobné údaje spracúva, dotknutá osoba má právo
                                získať prístup k týmto osobným údajom a
                                informácie o účele spracúvania osobných údajov;
                                kategórii spracúvaných osobných údajov;
                                identifikácii príjemcu alebo o kategórii
                                príjemcu, ktorému boli alebo majú byť osobné
                                údaje poskytnuté, najmä o príjemcovi v tretej
                                krajine alebo o medzinárodnej organizácii; ak je
                                to možné, o dobe uchovávania osobných údajov a
                                ak to nie je možné, informáciu o kritériách jej
                                určenia; práve požadovať od prevádzkovateľa
                                opravu osobných údajov týkajúcich sa dotknutej
                                osoby, ich vymazanie alebo obmedzenie ich
                                spracúvania, alebo o práve namietať spracúvanie
                                osobných údajov; práve podať sťažnosť dozornému
                                orgánu; zdroji osobných údajov, ak sa osobné
                                údaje nezískali od dotknutej osoby; existencii
                                automatizovaného individuálneho rozhodovania
                                vrátane profilovania. Dotknutá osoba má právo na
                                to, aby prevádzkovateľ bez zbytočného odkladu
                                opravil nesprávne osobné údaje, ktoré sa jej
                                týkajú. So zreteľom na účel spracúvania osobných
                                údajov má dotknutá osoba právo na doplnenie
                                neúplných osobných údajov. Dotknutá osoba má
                                právo na to, aby prevádzkovateľ bez zbytočného
                                odkladu vymazal osobné údaje, ktoré sa jej
                                týkajú, ak: osobné údaje už nie sú potrebné na
                                účel, na ktorý sa získali alebo inak spracúvali;
                                dotknutá osoba odvolá súhlas, na základe ktorého
                                sa spracúvanie osobných údajov vykonáva, a
                                neexistuje iný právny základ pre spracúvanie
                                osobných údajov; dotknutá osoba namieta
                                spracúvanie osobných údajov a neprevažujú žiadne
                                oprávnené dôvody na spracúvanie osobných údajov;
                                osobné údaje sa spracúvajú nezákonne. Dotknutá
                                osoba má právo na to, aby prevádzkovateľ
                                obmedzil spracúvanie osobných údajov, ak:
                                dotknutá osoba namieta správnosť osobných
                                údajov, a to počas obdobia umožňujúceho
                                prevádzkovateľovi overiť správnosť osobných
                                údajov; spracúvanie osobných údajov je nezákonné
                                a dotknutá osoba namieta vymazanie osobných
                                údajov a žiada namiesto toho obmedzenie ich
                                použitia; prevádzkovateľ už nepotrebuje osobné
                                údaje na účel spracúvania osobných údajov, ale
                                potrebuje ich dotknutá osoba na uplatnenie
                                právneho nároku, alebo dotknutá osoba namietala
                                voči spracúvaniu osobných údajov. Dotknutá osoba
                                má právo získať osobné údaje, ktoré sa jej
                                týkajú a ktoré poskytla prevádzkovateľovi, v
                                štruktúrovanom, bežne používanom a strojovo
                                čitateľnom formáte a má právo preniesť tieto
                                osobné údaje ďalšiemu prevádzkovateľovi, ak je
                                to technicky možné. Právo na prenosnosť sa
                                nevzťahuje na spracúvanie osobných údajov
                                nevyhnutné na splnenie úlohy realizovanej vo
                                verejnom záujme alebo pri výkone verejnej moci
                                zverenej prevádzkovateľovi. Dotknutá osoba má
                                právo namietať spracúvanie jej osobných údajov z
                                dôvodu týkajúceho sa jej konkrétnej situácie
                                vykonávané v rámci oprávneného záujmu
                                prevádzkovateľa, vrátane profilovania založeného
                                na týchto ustanoveniach. Prevádzkovateľ nesmie
                                ďalej spracúvať osobné údaje, ak nepreukáže
                                nevyhnutné oprávnené záujmy na spracúvanie
                                osobných údajov, ktoré prevažujú nad právami
                                alebo záujmami dotknutej osoby, alebo dôvody na
                                uplatnenie právneho nároku. Dotknutá osoba má
                                právo namietať spracúvanie osobných údajov,
                                ktoré sa jej týkajú, na účel priameho marketingu
                                vrátane profilovania v rozsahu, v akom súvisí s
                                priamym marketingom. Ak dotknutá osoba namieta
                                spracúvanie osobných údajov na účel priameho
                                marketingu, prevádzkovateľ ďalej osobné údaje na
                                účel priameho marketingu nesmie spracúvať.
                                Dotknutá osoba má právo na to, aby sa na ňu
                                nevzťahovalo rozhodnutie, ktoré je založené
                                výlučne na automatizovanom spracúvaní osobných
                                údajov vrátane profilovania a ktoré má právne
                                účinky, ktoré sa jej týkajú alebo ju obdobne
                                významne ovplyvňujú pokiaľ sa to netýka osobných
                                údajov nevyhnutných na uzavretie zmluvy alebo
                                plnenie zmluvy medzi dotknutou osobou a
                                prevádzkovateľom. Dotknutá osoba pri podozrení,
                                že jej osobné údaje sú spracúvané neoprávnene,
                                môže podať sťažnosť Úradu na ochranu osobných
                                údajov SR.
                            </p>
                        </li>
                    </ol>
                    <p>
                        <b>
                            Tieto všeobecné podmienky užívania nadobúdajú
                            platnosť a účinnosť dňa 5.3.2020.
                        </b>
                    </p>
                    <p>
                        Prevádzkovateľ je oprávnený tieto VPP upravovať a meniť,
                        pričom na platforme ActivityPoint zverejní vždy posledné
                        aktuálne znenie VPP, so zapracovaním prípadných zmien a
                        doplnení samotného textu VPP. Každá osoba používajúca a
                        aplikujúca VPP je vždy povinná oboznámiť sa s obsahom
                        aktuálneho znenia VPP. Prevádzkovateľ je zároveň
                        oprávnený meniť cenník ponúkaných služieb podľa svojich
                        potrieb, pričom o aktuálne platnom cenníku Zadávateľa
                        aktivity, ktorý prejaví záujem o spoluprácu, vhodným
                        spôsobom upovedomí.
                    </p>
                    <p>
                        Práva a povinnosti osôb zúčastnených a používajúcich
                        platformu ActivityPoint sa riadia VPP platnými a
                        účinnými v čase vzniku právneho vzťahu dotknutej osoby s
                        tým, že následné prípadné zmeny VPP sa vzťahujú aj na
                        práva a povinnosti osôb zúčastnených a používajúcich
                        platformu ActivityPoint odo dňa ich prijatia a
                        zverejnenia samotným Prevádzkovateľom.
                    </p>
                </Container>
            </Grid.Row>
        </Grid>
    );
}

export default GeneralConditionsText;
